import { toIdAndName } from '@src/utils/toIdAndName'
import { ScopeFilterKey } from './types'

export const getScopeFilters = <T extends { id: number }>(
  data: T | undefined,
  scope: Exclude<ScopeFilterKey, 'line_manager'> | 'employee',
) => {
  if (!data || scope === 'company') {
    return []
  }
  let columnName: ScopeFilterKey = scope === 'employee' ? 'line_manager' : scope
  return [
    {
      columnName,
      filters: [toIdAndName(String(data.id))],
      nonResettable: true,
    },
  ]
}
