import React, { useState } from 'react'
import { HStack, MoreBar, copyToClipboard } from '@revolut/ui-kit'

import { useGetPathWithWorkspace } from '@src/actions/RouterActions'
import { refreshEngagementSurveyStatistics } from '@src/api/engagement'
import {
  FormTabs,
  StartSurveyForm,
} from '@src/apps/People/Engagement/Survey/Preview/StartSurveyForm'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { ROUTES } from '@src/constants/routes'
import { Statuses } from '@src/interfaces'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import { PermissionTypes } from '@src/store/auth/types'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { pathToFullUrlWithBaseUrl, pathToUrl } from '@src/utils/router'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { usePublishResults } from '../hooks/usePublishResults'
import { ActionPopupVariant } from '../types'
import { SurveyPopup } from './SurveyPopup'
import { useIsEngagementManager } from '@src/features/Engagement/hooks/permissions'

const onCopyLinkToClipboard = (link?: string) => {
  if (link) {
    copyToClipboard(link).then(() => {
      pushNotification({
        value: 'Link copied to clipboard',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
    })
  }
}

interface Props {
  survey: EngagementSurveyInterface
  refetch: VoidFunction
  onShareResults: VoidFunction
}

export const SurveyActions = ({
  survey: {
    id,
    status,
    round_status,
    is_published,
    field_options: { permissions },
  },
  refetch,
  onShareResults,
}: Props) => {
  const [popupVariant, setPopupVariant] = useState<ActionPopupVariant>()
  const getPathWithWorkspace = useGetPathWithWorkspace()
  const { publishResults, unpublishResults, isPending } = usePublishResults(id, refetch)

  // As the refreshing statistics can take a long amount of time we want to disable button when it was clicked
  // and for now only allow users to click it once per page load
  // there is a backend feature request to resturn information whether the background task is running
  const [resultStatisticsRefreshed, setStatisticsResultsRefreshed] = useState(false)
  const showStatusPopup = useShowStatusPopup()

  const isEngagementManager = useIsEngagementManager()
  const canEdit = permissions?.includes(PermissionTypes.ChangeEngagementSurvey)
  const canArchive = permissions?.includes(PermissionTypes.ArchiveEngagementSurvey)
  const canCancel =
    permissions?.includes(PermissionTypes.CancelEngagementSurvey) &&
    (round_status?.id === Statuses.running || round_status?.id === Statuses.planned)
  const canGetSurveyLink = round_status?.id === Statuses.running

  const handleRefreshResults = async () => {
    try {
      setStatisticsResultsRefreshed(true)
      await refreshEngagementSurveyStatistics({ surveyId: id })
      showStatusPopup({
        status: 'pending',
        title: 'Survey results are being refreshed now.',
        description: 'It may take some time',
      })
    } catch (error) {
      if (error.response?.status === 400) {
        showStatusPopup({
          status: 'pending',
          title: 'Refresh for this survey was already triggered and not finished yet',
          description: 'It may take some time',
        })
      } else {
        showStatusPopup({
          status: 'error',
          title: 'Something went wrong',
          description: 'Please try again',
        })
      }
    }
  }

  return (
    <HStack space="s-4">
      <MoreBar maxCount={2}>
        {canEdit && (
          <MoreBar.Action
            useIcon="Pencil"
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.ENGAGEMENT.EDIT, { step: 'basic', id })}
          >
            Edit survey
          </MoreBar.Action>
        )}
        {round_status?.id === Statuses.inactive && (
          <MoreBar.Action useIcon="Play" onClick={() => setPopupVariant('start')}>
            Start survey
          </MoreBar.Action>
        )}
        {canGetSurveyLink && (
          <MoreBar.Action
            useIcon="Link"
            onClick={() => {
              onCopyLinkToClipboard(
                pathToFullUrlWithBaseUrl(
                  getPathWithWorkspace(ROUTES.ENGAGEMENT_PAGE),
                  undefined,
                  {
                    survey_id: String(id),
                  },
                ),
              )
            }}
          >
            Get survey link
          </MoreBar.Action>
        )}
        {isEngagementManager && (
          <MoreBar.Action useIcon="ShareIOs" onClick={onShareResults}>
            Share results
          </MoreBar.Action>
        )}
        <MoreBar.Action
          useIcon="Questionnaire"
          variant={is_published ? 'negative' : 'primary'}
          pending={isPending}
          onClick={is_published ? unpublishResults : publishResults}
        >
          {is_published ? 'Unpublish results' : 'Publish results'}
        </MoreBar.Action>
        {round_status?.id === Statuses.running && (
          <MoreBar.Action
            onClick={handleRefreshResults}
            useIcon="ArrowExchange"
            disabled={resultStatisticsRefreshed}
          >
            Refresh results
          </MoreBar.Action>
        )}
        {canCancel && (
          <MoreBar.Action
            variant="negative"
            useIcon="LightningOff"
            onClick={() => setPopupVariant('close')}
          >
            Deactivate
          </MoreBar.Action>
        )}
        {canArchive && (
          <MoreBar.Action
            variant="negative"
            useIcon={round_status?.id !== Statuses.archived ? 'Archive' : 'Unarchive'}
            onClick={() =>
              setPopupVariant(
                round_status?.id !== Statuses.archived ? 'archive' : 'unarchive',
              )
            }
          >
            {round_status?.id !== Statuses.archived ? 'Archive' : 'Unarchive'}
          </MoreBar.Action>
        )}
      </MoreBar>
      <StartSurveyForm
        surveyStatus={status}
        surveyId={id}
        initialTab={popupVariant === 'start' ? FormTabs.SendNow : FormTabs.ScheduleLater}
        isOpen={popupVariant === 'start' || popupVariant === 'schedule'}
        onClose={() => setPopupVariant(undefined)}
        refetch={refetch}
      />
      <SurveyPopup
        surveyId={id}
        refetch={refetch}
        isOpen={
          !!popupVariant && ['close', 'archive', 'unarchive'].includes(popupVariant)
        }
        variant={popupVariant}
        onClose={() => setPopupVariant(undefined)}
      />
    </HStack>
  )
}
