import React, { useState } from 'react'
import isNumber from 'lodash/isNumber'
import lowerCase from 'lodash/lowerCase'
import pluralize from 'pluralize'
import {
  Avatar,
  Box,
  Carousel,
  Ellipsis,
  MoreBar,
  TableWidget,
  Text,
  Tile,
  TileSkeleton,
  Token,
  VStack,
} from '@revolut/ui-kit'

import { InternalLink } from '@components/InternalLink/InternalLink'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { getEmployeeTimeOffBalances } from '@src/api/timeOff'
import {
  timeOffBalanceAccruedCountColumn,
  timeOffBalanceBookedCountColumn,
  timeOffBalanceCarriedOverCountColumn,
  timeOffBalanceEndOfCycleCountColumn,
  timeOffBalancePolicyNameColumn,
  timeOffBalanceStatusColumn,
  timeOffBalanceTakenCountColumn,
} from '@src/constants/columns/timeOff'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { policyCategoryToIcon } from '@src/features/TimeOffCalendarTable/helpers'
import { Statuses } from '@src/interfaces'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { EmployeeInterface } from '@src/interfaces/employees'
import { EmployeeTimeOffBalancesInterface } from '@src/interfaces/timeOff'
import {
  ContactHRTeamButton,
  EmptyTableState,
  UserGuidesButton,
} from '@src/pages/EmployeeProfile/Preview/TimeOff/common'
import { pathToUrl } from '@src/utils/router'
import {
  canAddTimeOffAbsences,
  canAddTimeOffRequests,
  canViewPolicyAssignments,
} from '../../Preview/ProfileSummary/common'

const ROW: RowInterface<EmployeeTimeOffBalancesInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.TIME_OFF_BALANCE.PREVIEW, { id: data.id })),
  cells: [
    {
      ...timeOffBalancePolicyNameColumn,
      width: 400,
    },
    {
      ...timeOffBalanceEndOfCycleCountColumn,
      width: 150,
    },
    {
      ...timeOffBalanceBookedCountColumn,
      width: 100,
    },
    {
      ...timeOffBalanceTakenCountColumn,
      width: 100,
    },
    {
      ...timeOffBalanceAccruedCountColumn,
      width: 100,
    },
    {
      ...timeOffBalanceCarriedOverCountColumn,
      width: 100,
    },
    {
      ...timeOffBalanceStatusColumn,
      width: 100,
    },
  ],
}

const getShowAllBalancesOffFilter = (isTableView: boolean) => [
  {
    columnName: 'status',
    filters: [
      { id: Statuses.open, name: Statuses.open },
      isTableView ? { id: Statuses.pending, name: Statuses.pending } : null,
    ].filter(Boolean),
  },
]

const showAllBalancesOnFilter = [
  {
    columnName: 'status',
    filters: [],
  },
]

type Props = {
  data: EmployeeInterface
}
export const BalancesWidget = ({ data }: Props) => {
  const [showAllBalances, setShowAllBalances] = useState(false)

  const { tabBar, currentTab } = useTabBarSwitcher({
    tabs: ['tiles', 'table'],
    useIcons: { tiles: 'ViewGrid', table: '16/ListBullet' },
    defaultTab: 'tiles',
    highlightSelected: false,
    onTabChange: tab => {
      if (tab === 'table') {
        table.onFilterChange(
          showAllBalances ? showAllBalancesOnFilter : getShowAllBalancesOffFilter(true),
        )
      } else if (tab === 'tiles') {
        table.onFilterChange(getShowAllBalancesOffFilter(false))
      }
    },
  })

  const filters: FilterByInterface[] = showAllBalances
    ? showAllBalancesOnFilter
    : getShowAllBalancesOffFilter(currentTab === 'table')

  const table = useTable(
    { getItems: getEmployeeTimeOffBalances(data.id) },
    filters,
    undefined,
    { disableQuery: true },
  )

  const TILE_SIZE = 180
  const tileSizeProps = { width: TILE_SIZE, height: TILE_SIZE }

  const canAddRequest = canAddTimeOffRequests(data)
  const canAddAbsence = canAddTimeOffAbsences(data)
  const canViewPolicies = canViewPolicyAssignments(data)

  const emptyState = (
    <EmptyTableState
      usePlaceholder
      title="No time off policies assigned yet"
      description={
        <>
          Please reach out to the HR Team in order to get the applicable time off policies
          assigned to your profile.
          <br />
          Once the relevant policies are assigned you will be able to see your available
          balance and you will be able to submit time off requests.
        </>
      }
      actions={
        <>
          <ContactHRTeamButton />
          <UserGuidesButton />
        </>
      }
    />
  )

  const renderTiles = () => {
    if (table.loading) {
      return (
        <Carousel>
          <Carousel.Item {...tileSizeProps}>
            <TileSkeleton variant="small" />
          </Carousel.Item>
          <Carousel.Item {...tileSizeProps}>
            <TileSkeleton variant="small" />
          </Carousel.Item>
          <Carousel.Item {...tileSizeProps}>
            <TileSkeleton variant="small" />
          </Carousel.Item>
        </Carousel>
      )
    }
    if (table.data.length) {
      return (
        <Carousel>
          {table.data.map(balance => {
            const unitName = lowerCase(balance.unit.name)
            const availableUnitsCount = pluralize(
              unitName,
              balance.available_balance || 0,
              true,
            )
            const takenUnitsCount = pluralize(
              unitName,
              typeof balance.taken === 'number' ? Number(balance.taken.toFixed(1)) : 0,
              true,
            )

            return (
              <Carousel.Item key={balance.id} {...tileSizeProps}>
                <Tile
                  variant="small"
                  bg={Token.color.greyTone2}
                  color={Token.color.foreground}
                  onClick={() =>
                    navigateTo(
                      pathToUrl(ROUTES.FORMS.TIME_OFF_BALANCE.PREVIEW, {
                        id: balance.id,
                      }),
                    )
                  }
                >
                  <Tile.Avatar>
                    <Avatar useIcon={policyCategoryToIcon(balance.policy.category?.id)} />
                  </Tile.Avatar>
                  <Tile.Title>
                    <Ellipsis maxWidth={140} tooltip="auto">
                      {balance.policy.category?.name || 'N/A'}
                    </Ellipsis>
                  </Tile.Title>
                  <Tile.Content>
                    <VStack>
                      {balance.balance_type.id === 'unlimited' ? (
                        <Text color={Token.color.green}>Unlimited</Text>
                      ) : (
                        <Text
                          color={
                            isNumber(balance.available_balance) &&
                            balance.available_balance > 0
                              ? Token.color.success
                              : Token.color.danger
                          }
                        >
                          {availableUnitsCount} available
                        </Text>
                      )}

                      <Text variant="small" color={Token.color.greyTone50}>
                        {takenUnitsCount} taken
                      </Text>
                    </VStack>
                  </Tile.Content>
                </Tile>
              </Carousel.Item>
            )
          })}
        </Carousel>
      )
    }
    return emptyState
  }

  return (
    <TableWidget>
      <TableWidget.Actions>
        <MoreBar>
          {canAddRequest && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.GENERAL, {
                employeeId: data.id,
              })}
              useIcon="Plus"
              disabled={!table.data.length}
            >
              Request time off
            </MoreBar.Action>
          )}
          {canAddAbsence && (
            <MoreBar.Action
              useIcon="Plus"
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_ABSENCE.GENERAL, {
                employeeId: data.id,
              })}
              disabled={!table.data.length}
            >
              Record absence
            </MoreBar.Action>
          )}
          {canViewPolicies && (
            <MoreBar.Action
              useIcon="Pencil"
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE.TIME_OFF.POLICIES, {
                id: data.id,
              })}
            >
              Manage policies
            </MoreBar.Action>
          )}
          {currentTab === 'table' && (
            <MoreBar.Action
              useIcon="Switches"
              variant={showAllBalances ? 'accent' : undefined}
              onClick={() => {
                setShowAllBalances(!showAllBalances)
                table.onFilterChange(
                  showAllBalances
                    ? getShowAllBalancesOffFilter(true)
                    : showAllBalancesOnFilter,
                )
              }}
            >
              Show past balances
            </MoreBar.Action>
          )}
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Filters>{tabBar}</TableWidget.Filters>
      <TableWidget.Table>
        {currentTab === 'tiles' && (
          <Box width="calc(100% - 16px)" pl="s-16">
            {renderTiles()}
          </Box>
        )}
        {currentTab === 'table' && (
          <AdjustableTable
            name={TableNames.EmployeeTimeOffBalances}
            useWindowScroll
            row={ROW}
            emptyState={emptyState}
            {...table}
          />
        )}
      </TableWidget.Table>
    </TableWidget>
  )
}
